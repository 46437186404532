<script setup lang="ts">
const { t: $t } = useI18n()

import type { FormInst, FormItemRule } from 'naive-ui'
import type { SubFolderCreateOrUpdate } from '~/types'

interface FormRules {
  [itemValidatePath: string]: FormItemRule | Array<FormItemRule> | FormRules
}

const props = defineProps<{
  payload: SubFolderCreateOrUpdate
  parentFolderName?: string
  entity: FileFolderEntity
}>()
const emit = defineEmits<{ (e: 'close'): void }>()

/** Form */
const formRef = ref<FormInst | null>(null)
const formData = ref<SubFolderCreateOrUpdate>(props.payload)

const rules: FormRules = {
  data: {
    name: {
      required: true,
      trigger: 'blur',
      message: $t('fileFolder.input.name.validation'),
    },
  },
}

/** Mutations */
const { $trpc, queryClient, useMutation, makeTrpcErrorToast } = useMutationHelpers()
const notification = useNotification()

const createFolder = useMutation({
  mutationFn: $trpc.fileFolder.createSubFolder.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('fileFolder.create.error') }),
  onSuccess: async () => {
    await Promise.all(getFileFolderQueryInvalidations(props.entity, queryClient))
    notification.success({ title: $t('fileFolder.create.success'), duration: 4500 })
    emit('close')
  },
})

const updateFolder = useMutation({
  mutationFn: $trpc.fileFolder.updateSubFolder.mutate,
  onError: makeTrpcErrorToast(notification, { description: $t('fileFolder.update.error') }),
  onSuccess: async () => {
    await Promise.all(getFileFolderQueryInvalidations(props.entity, queryClient))
    notification.success({ title: $t('fileFolder.update.success'), duration: 4500 })
    emit('close')
  },
})

const isPending = computed(() => createFolder.isPending.value || updateFolder.isPending.value)

function submitHandler() {
  formRef.value?.validate((errors) => {
    if (errors) {
      return
    }
    if (formData.value.mode === 'create') {
      createFolder.mutate(formData.value.data)
    } else {
      updateFolder.mutate(formData.value.data)
    }
  })
}

const currentLabel = computed(() => {
  return props.parentFolderName
    ? $t('fileFolder.parentFolder', { folderName: props.parentFolderName })
    : ''
})
</script>

<template>
  <ThePopup
    :title="$t(`fileFolder.modal.${formData.mode}.title`)"
    width="450px"
    show
    @close="emit('close')"
  >
    <n-form
      ref="formRef"
      :rules="rules"
      @submit.prevent="submitHandler"
    >
      <n-form-item :label="currentLabel" path="name">
        <n-input v-model:value="formData.data.name" :disabled="isPending" :placeholder="$t('fileFolder.input.name.placeholder')">
          <template #prefix>
            <Icon name="material-symbols:folder-outline-rounded" size="14" />
          </template>
        </n-input>
      </n-form-item>
      <div class="flex justify-end gap-2">
        <n-button quaternary :disabled="isPending" @click="emit('close')">
          {{ $t('button.cancel') }}
        </n-button>
        <n-button type="primary" :disabled="isPending" :loading="isPending" attr-type="submit">
          {{ $t(`fileFolder.modal.button.${formData.mode}`) }}
        </n-button>
      </div>
    </n-form>
  </ThePopup>
</template>
